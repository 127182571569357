import React, { useState, useEffect } from 'react';
import { AGENT_SEARCH_PATH, EXP_COMMERCIAL_PATH } from 'utils/agent-endpoint';
import Button, { SECONDARY_THEME } from 'components/control/button';
import { AgentFull, fetchAgent } from 'utils/agent-endpoint';
import BackArrowIcon from 'components/icon/back-arrow-icon';
import FacebookIcon from 'components/icon/facebook-icon';
import LanguageIcon from '@mui/icons-material/Language';
import CopyButton from 'components/control/copy-button';
import { buildClassName } from 'utils/build-class-name';
import PhoneIcon from 'components/icon/phone-icon';
import MailIcon from 'components/icon/mail-icon';
import useSizeClass from 'hooks/use-size-class';
import { useThemeContext } from 'contexts';
import styles from './style.module.scss';
import { useRouter } from 'next/router';
import NextImage from 'next/image';
import Link from 'components/link';
import Script from 'next/script';
import { agentSearchIds } from 'constants/test-constants';
import { isClientSide } from 'utils/host-config';
import { ThemeNames } from 'types/themes';
import LinkedInIcon from 'components/icon/linkedin-icon';
import TwitterIcon from 'components/icon/twitter-icon';
import InstagramIcon from 'components/icon/instagram-icon';
import YoutubeIcon from 'components/icon/youtube-icon';
import TiktokIcon from 'components/icon/tiktok-icon';

import { AgentSocialMediaLinks } from 'constants/agent-profile';
import { findUUIDWithinStrings } from 'utils/uuid/uuid';

export const styleRichContent = (content?: string) => {
  if (!content) return null;
  const cleanedContent = content.replace(/<\/?pre>/g, ''); // Remove <pre> and </pre> tags from the content
  const lines = cleanedContent.split('\n');
  const styledLines = lines.map(line => `<span style="display: block; padding-bottom: 1em;">${line}</span>`).join('');
  return styledLines;
};

interface AgentPageProps {
  eventTrackers: {
    onPageView: () => void;
    onGoBack: () => void;
    onSocialMediaLinkClick: (AgentSocialMediaLinks: AgentSocialMediaLinks) => void;
    onCopyEmailClick: () => void;
    onCopyPhoneClick: () => void;
    onExploreServicesClick: () => void;
    onJoinExpClick: () => void;
  };
}

function AgentPage({ eventTrackers }: AgentPageProps) {
  const router = useRouter();
  const { themeName, theme } = useThemeContext();
  const isMobile = useSizeClass({ max: 600 });
  const [agentInfo, setAgentInfo] = useState<AgentFull | null>(null);
  const [scriptLoaded, setScriptLoaded] = useState(isClientSide() && typeof window.grecaptcha !== 'undefined');
  const { onPageView, onGoBack, onSocialMediaLinkClick, onCopyEmailClick, onCopyPhoneClick, onExploreServicesClick, onJoinExpClick } = eventTrackers;

  const agentInfoFromQuery = router.query.dynamic && router.query.dynamic as string[];  
  const agentId = findUUIDWithinStrings(agentInfoFromQuery);

  const isCommercial = themeName === ThemeNames.EXP_COMMERCIAL;

  const agentPathname = isCommercial ? EXP_COMMERCIAL_PATH : AGENT_SEARCH_PATH;

  useEffect(() => {
    if (!agentId) {
      router.push(agentPathname);
    }

    if (!scriptLoaded) return;
    fetchAgent(agentId).then((result: AgentFull | Error) => {
      if (result instanceof Error) {
        console.error(result);
      } else {
        setAgentInfo(result);
        setImgSrc(result.photo ? result.photo : '/next/assets/images/agent-search/no-agent-photo.png');
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);

  const [imgSrc, setImgSrc] = useState(
    agentInfo?.photo ? agentInfo.photo : '/next/assets/images/agent-search/no-agent-photo.png'
  );

  useEffect(() => {
    // Track page view when the component mounts
    onPageView();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    onGoBack();
    if (router.query.prevPath) {
      router.push(router.query.prevPath as string);
    } else {
      router.push(agentPathname);
    }
  };

  const styledBio = styleRichContent(agentInfo?.bio);
  const openSocialMediaLink = (link: string, linkType: AgentSocialMediaLinks) => {
    onSocialMediaLinkClick(linkType);
    const url = link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    window.open(url, '_blank');
  };

  const handleMobileEmailButtonClick = (email: string) => {
    onCopyEmailClick();
    window.open(`mailto:${email}`);
  };
  const handleMobilePhoneButtonClick = (phone: string) => {
    onCopyPhoneClick();
    window.location.href = `tel:${phone}`;
  };

  return (
    <div className={styles.component}>
      <a onClick={handleGoBack} className={styles['top-back-button']}>
        <BackArrowIcon className={styles['back-arrow']} /> <span>Return to Search</span>
      </a>
      <div className={buildClassName(styles.content, isCommercial && styles['is-commercial'])}>
        <div className={styles['main-info']}>
          <h1 data-testid={agentSearchIds.agentProfileFirstName}>{agentInfo?.firstName}</h1>
          <h1 data-testid={agentSearchIds.agentProfileLastName}>{agentInfo?.lastName}</h1>
          <div className={styles['agent-picture']}>
            <NextImage
              alt={'agent-picture'}
              src={imgSrc}
              onError={() => setImgSrc('/next/assets/images/agent-search/no-agent-photo.png')}
              priority
              fill
              sizes='(max-width: 639px) 480px, 640px'
            />
          </div>
        </div>
        <div className={styles['bio-container']}>
          <div className={styles['bio']}>
            <h2 className={styles['bio-heading']}>
              Contact
            </h2>
            <div className={styles['bio-content']} data-testid={agentSearchIds.agentProfileContactInfo}>
              {!isMobile ?
                <>
                  {agentInfo?.email &&
                    <div className={styles['personal-contact']}>
                      <p>{agentInfo.email}</p>
                      <CopyButton content={agentInfo.email} tooltipText={'Email Copied'} onClick={onCopyEmailClick} />
                    </div>
                  }
                  {agentInfo?.phoneNumber &&
                    <div className={styles['personal-contact']}>
                      <p>{agentInfo.phoneNumber}</p>
                      <CopyButton content={agentInfo.phoneNumber} tooltipText={'Number Copied'} onClick={onCopyPhoneClick} />
                    </div>
                  }
                </>
                :
                <>
                  <div className={styles['social-media']}>
                    {agentInfo?.email &&
                      <Button
                        Icon={MailIcon}
                        theme={SECONDARY_THEME}
                        label={'Email'}
                        className={styles['custom-button-style']}
                        onClick={() => handleMobileEmailButtonClick(agentInfo.email)}
                      />
                    }
                    {agentInfo?.phoneNumber &&
                      <Button
                        Icon={PhoneIcon}
                        theme={SECONDARY_THEME}
                        label={'Phone'}
                        className={styles['custom-button-style']}
                        onClick={() => handleMobilePhoneButtonClick(agentInfo.phoneNumber)}
                      />
                    }
                  </div>
                  <br/>
                </>
              }
              <div className={styles['social-media']}>
                {agentInfo?.facebook &&
                  <Button
                    Icon={FacebookIcon}
                    theme={SECONDARY_THEME}
                    label={'Facebook'}
                    className={styles['custom-button-style']}
                    onClick={() => openSocialMediaLink(agentInfo.facebook, AgentSocialMediaLinks.FACEBOOK)}
                  />
                }
                {agentInfo?.website &&
                  <Button
                    Icon={LanguageIcon}
                    theme={SECONDARY_THEME}
                    label={'Website'}
                    className={styles['custom-button-style']}
                    onClick={() => openSocialMediaLink(agentInfo.website, AgentSocialMediaLinks.WEBSITE)}
                  />
                }
                {agentInfo?.linkedIn && 
                  <Button
                    Icon={LinkedInIcon}
                    theme={SECONDARY_THEME}
                    label={'LinkedIn'}
                    className={styles['custom-button-style']}
                    onClick={() => openSocialMediaLink(agentInfo.linkedIn, AgentSocialMediaLinks.LINKEDIN)}
                  />
                }
                {agentInfo?.twitter && 
                  <Button
                    Icon={TwitterIcon}
                    theme={SECONDARY_THEME}
                    label={'Twitter'}
                    className={buildClassName(styles['custom-button-style'], styles['twitter-button'])}
                    onClick={() => openSocialMediaLink(agentInfo.twitter, AgentSocialMediaLinks.TWITTER)}
                  />
                }
                {agentInfo?.instagram && 
                  <Button
                    Icon={InstagramIcon}
                    theme={SECONDARY_THEME}
                    label={'Instagram'}
                    className={styles['custom-button-style']}
                    onClick={() => openSocialMediaLink(agentInfo.instagram, AgentSocialMediaLinks.INSTAGRAM)}
                  />
                }
                {agentInfo?.youtube &&
                  <Button
                    Icon={YoutubeIcon}
                    theme={SECONDARY_THEME}
                    label={'YouTube'}
                    className={buildClassName(styles['custom-button-style'], styles['youtube-button'])}
                    onClick={() => openSocialMediaLink(agentInfo.youtube, AgentSocialMediaLinks.YOUTUBE)}
                  />
                }
                {agentInfo?.tiktok && 
                  <Button
                    Icon={TiktokIcon}
                    theme={SECONDARY_THEME}
                    label={'Tiktok'}
                    className={styles['custom-button-style']}
                    onClick={() => openSocialMediaLink(agentInfo.tiktok, AgentSocialMediaLinks.TIKTOK)}
                  />
                }
              </div>
            </div>
          </div>
          <div className={styles['bio']}>
            <h2 className={styles['bio-heading']}>
              About
            </h2>
            <div className={styles['bio-content']} data-testid={agentSearchIds.agentProfileAbout}>
              {agentInfo?.city && agentInfo?.state &&
                <div className={styles['bio-content-heading']}>
                  <p>
                    {agentInfo.city}, {agentInfo.state} {isCommercial ? 'Commercial Real Estate Advisor' : 'Real Estate Agent'}
                  </p>
                </div>
              }
              {styledBio &&
                <div className={styles['about']}>
                  <div className={styles['about-inner-html']} dangerouslySetInnerHTML={{ __html: styledBio }} />
                </div>
              }
            </div>
          </div>
          {(!!agentInfo?.languages.length || !!agentInfo?.specializations.length || !!agentInfo?.activeLocations.length) &&
            <div className={styles['bio']}>
              <h2 className={styles['bio-heading']}>
                Descriptions
              </h2>
              <div className={styles['bio-content']}>
                {!!agentInfo?.languages.length &&
                  <>
                    <div className={styles['bio-content-heading']}>
                      <p>Languages</p>
                    </div>
                    <div className={styles['about']}>
                      <p>
                        {agentInfo.languages.join(', ')}
                      </p>
                    </div>
                    <br/><br/>
                  </>
                }
                {!!agentInfo?.specializations.length &&
                  <>
                    <div className={styles['bio-content-heading']}>
                      <p>Specializations</p>
                    </div>
                    <div className={styles['about']}>
                      <p>
                        {agentInfo.specializations.join(', ')}
                      </p>
                    </div>
                    <br/><br/>
                  </>
                }
                {!!agentInfo?.certifications.length &&
                  <>
                    <div className={styles['bio-content-heading']}>
                      <p>Certifications</p>
                    </div>
                    <div className={styles['about']}>
                      <p>
                        {agentInfo.certifications.join(', ')}
                      </p>
                    </div>
                    <br/><br/>
                  </>
                }
                {!!agentInfo?.designations.length &&
                  <>
                    <div className={styles['bio-content-heading']}>
                      <p>Designations</p>
                    </div>
                    <div className={styles['about']}>
                      <p>
                        {agentInfo.designations.join(', ')}
                      </p>
                    </div>
                    <br/><br/>
                  </>
                }
                {!!agentInfo?.activeLocations.length &&
                  <>
                    <div className={styles['bio-content-heading']}>
                      <p>Locations Served</p>
                    </div>
                    <div className={styles['about']}>
                      <p>
                        {agentInfo.activeLocations.map(location => `${location.city}, ${location.state}`).join('; ')}
                      </p>
                    </div>
                  </>
                }
              </div>
            </div>
          }
          {!isCommercial ? (
            <>
              <div className={buildClassName(styles['bio'], styles['bio-small-margin'])}>
                <h2 className={styles['bio-heading']}/>
                <div className={styles['bio-content']}>
                  <div className={buildClassName(styles['learn-more'], styles['learn-more-no-bg'])}>
                    <div className={styles['copy']}>
                      <h3>Explore Our Services</h3>
                      <p>Are you a real estate agent? View the services that eXp can provide you as an eXp agent.</p>
                    </div>
                    <div className={styles['cta']}>
                      <Link href={'/explore-exp'}>
                        <Button label={'Learn More'}
                          theme={SECONDARY_THEME}
                          onClick={onExploreServicesClick} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['bio']}>
                <h2 className={styles['bio-heading']}/>
                <div className={styles['bio-content']}>
                  <div className={styles['learn-more']}>
                    <div className={styles['copy']}>
                      <h3>Join eXp Realty</h3>
                      <p>Your success fuels our obsession for pushing the real estate industry beyond its edges.</p>
                    </div>
                    <div className={styles['cta']}>
                      <Link href={theme.footerContent.joinUsLink} target='_blank'>
                        <Button label={'Learn More'}
                          theme={SECONDARY_THEME}
                          onClick={onJoinExpClick} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : <div style={{ height: '300px' }}/>}
        </div>
      </div>
      {!scriptLoaded &&
        <Script src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
          onLoad={() => setScriptLoaded(true)}
        />
      }
    </div>
  );
}

export default AgentPage;
